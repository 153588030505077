import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { PATH } from "../../../../assets/variables";
import { fetchToken } from "../../../customHooks/hooks";
import { setUserPostulo, setPostFinalizado } from "../../../../store/slices/userAll";
import { Hr } from "../../../../Styled";
import Alert from "../../Common/Alert/Alert";
import { ContainerButton } from "../../Common/Alert/Styled";
import CapVideo from "../../Common/CapVideo/CapVideo";
import Properties from "../../Home/Feed/Caps/Cap/CapProperties/Properties";
import CapSkills from "../../Home/Feed/Caps/Cap/CapSkills/CapSkills";
import { BtnSubmit } from "../../PostDetails/PostModal/Styled";
import { ContainerHalf, OffSet } from "../Style";

import { BtnApply, BtnBlue, BtnCancelar, HistorialContent, SectionStyled, WrapperContent } from "./styled";

const MainVacancy = ({ id: idPost }) => {
  
  //varios componentes vienen del componente principal "Cap" que esta en la pagina "inicio", estos componentes tambien estan en la pagina "mis-publicaciones" en el componente "PostMain"
  const [modal, setModal] = useState(false);
  const [dataUsers, setdataUsers] = useState([]);
  const [dataHistorial, setdataHistorial] = useState([]);
  const [finalizado, setFinalizado] = useState();


  const userPostulo = useSelector(store => store.userAll);
  const userData = useSelector(store => store.user.data);
  const token = useSelector(store => store.usuario.token);


    const postFinalizado = useSelector(store => store.userAll.postfinalizado);
  

  const dispatch = useDispatch();

  async function showAlert(idPost){
    const dataPost = {
      id_publicacion: idPost
    };
   const post = await fetchToken({
      endpoint: "Publicacion/AddTokenPostulacion",
      method: "POST",
      body: dataPost,
      alt: { error: true },
      token:token
    });
    if(post.FlgOk === 1){
      setModal(false)
      dispatch(setUserPostulo(dataPost.id_publicacion))
    }
    
  }
  const FetchData = async () => {
    const datos = await fetchToken({ endpoint: `Publicacion/GetTokenPostulacionPost?id_publicacion=${userPostulo.postulo == "" ? id : userPostulo.postulo}`,token:token });
    setdataUsers(datos)
  }
  const FetchHistorial = async () => {
    const historial = await fetchToken({ endpoint: `Publicacion/GetHistorial?idPost=${idPost}&fkPersona=${userData.fk_colaborador}` });
    setdataHistorial(historial)
  }
 
  useEffect(() => {
    FetchData()
    FetchHistorial()

  }, [userPostulo.postulo])

  
  const {
    data: {
      id,
      cargo,
      gerencia,
      estamento,
      ubicacion,
      jornada,
      mision,
      funciones,
      descripcion,
      archivo,
      nombreCompleto
    },
    listadoHabilidades,
    listadoIntereses,
    listadoLenguajes
  } = useSelector(
    store =>
      store.posts.totalPosts.filter(post => post.data.id === Number(idPost))[0]
  );

  const capData = {
    //change for real data
    interests: listadoIntereses.map(i => ({
      ...i,
      title: i.nombre,
      id: i.idTag
    })),
    languages: listadoLenguajes.map(l => ({
      ...l,
      title: l.nombre,
      id: l.idTag
    })),
    skills: listadoHabilidades.map(s => ({
      ...s,
      title: s.nombre,
      id: s.idTag
    }))
  };

  return (
    <div>
      <OffSet />
      <ContainerHalf scroll>
        <CapVideo post={true} archivo={archivo} />
        <Properties
          cargo={cargo}
          gerencia={gerencia}
          estamento={estamento}
          ubicacion={ubicacion}
          jornada={jornada}
          jefatura={nombreCompleto}
        />
        {userData.urlCV != null ? (
                  <WrapperContent>
                  {  postFinalizado != true ? (
                    dataUsers != null ? 
                    <BtnBlue disabled>
                    <p>Haz postulado a este cargo</p>
                    </BtnBlue>
                  : (
                  <BtnBlue onClick={() => setModal(true)}>
                    <p>Postular al cargo</p>
                    <img src={PATH + "img/ArrowRight.svg"} alt="Arrow Right" />
                  </BtnBlue>
                  ) 
                  ) : (<BtnBlue disabled>
                    <p>Publicación Finalizada</p>
                    </BtnBlue>)
        
                  
            }
        
                </WrapperContent>
        ): (
          <WrapperContent><h3 style={{fontWeight:600}}>Sube tu CV <NavLink to={"/perfil"}>aqui</NavLink> para postular</h3></WrapperContent>
        )}
        {dataHistorial.fechaAgenda != null ? (
        <HistorialContent>
        <img src={PATH + "img/Calendar.svg"} alt="Arrow Right" /> <h6>Fecha de entrevista: <strong>{dataHistorial.fechaAgenda}</strong></h6>
        </HistorialContent>
        ) : ''}
        {dataHistorial.linkAira != null ? (
        <HistorialContent>
        
        <img src={PATH + "img/aira.svg"} alt="Arrow Right" /> <h6>Link Aira: <strong><a href={dataHistorial.linkAira != null ? dataHistorial.linkAira : ''} target="_blank">{dataHistorial.linkAira}</a></strong></h6>
        </HistorialContent>
        ) : ''}
        {dataHistorial.motivoRechazo != null ? (
        <HistorialContent>
        
        <img src={PATH + "img/icono-motivo-rechazo.svg"} alt="Arrow Right" /> <h6><strong>No fuiste seleccionado para este cargo:</strong> {dataHistorial.motivoRechazo} </h6>
        </HistorialContent>
        ) : ''}
        <CapSkills itemsData={capData} off/>
        <WrapperContent direction="column">
          <Hr />
          <SectionStyled>
            <h3>Mision:</h3>
            {mision}
          </SectionStyled>
          <SectionStyled>
            <h3>Descripcion:</h3>
            <p>{descripcion}</p>
          </SectionStyled>
          <SectionStyled>
            <h3>Requisitos:</h3>
            <p>{funciones}</p>
          </SectionStyled>

        </WrapperContent>
      </ContainerHalf>
      <Alert
      estado={modal}
      shoModal={setModal}
      >
        <img src={PATH + "img/icono-info.svg"} alt="info"/>
        <h3>Confirmación</h3>
        <p>¿Estás seguro de que deseas postular a esta oferta ?
        </p>
        <ContainerButton>
        <BtnCancelar onClick={()=> setModal(false)}>Cancelar</BtnCancelar>
        <BtnBlue onClick={() => showAlert(idPost)}>Postular</BtnBlue>
        </ContainerButton>

      </Alert>
    </div>
  );
};

export default MainVacancy;
