import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colors, PATH } from "../../../../assets/variables";
import { fetchToken } from "../../../customHooks/hooks";
import { ContainerModal, Hr, SubTitle, Title } from "../../../../Styled";
import { calculator } from "../../../../utils/calculator";
import { Icon } from "../../Common/IconProfile/Styled";
import { OffSet } from "../../VacanciesDetails/Style";
import { ContainerHalfCustom } from "../Styled";
import {v4} from "uuid"
import {
  ItemBox,
  HeaderSection,
  RowWrapper,
  Score,
  Hv,
  SkeletonContainer
} from "./Styled";
import { ReactComponent as Star } from "../../../../assets/img/Star.svg";
import { ReactComponent as CheckPostulo } from "../../../../assets/img/checkPostulo.svg";
import { SkeletonLoader } from "../../Common/Skeleton/Styled";
import Alert from "../../Common/Alert/Alert";
import { BtnLink } from "../../Home/Feed/Caps/Cap/Styled";
import { ContainerButton } from "../../Common/Alert/Styled";
import { BtnBlue, BtnCancelar } from "../../VacanciesDetails/MainVacancy/styled";
import { BtnClose, BtnSubmit, ModalWrapper, Section } from "../PostModal/Styled";
import CapSkills from "../../Home/Feed/Caps/Cap/CapSkills/CapSkills";
import { añosCargo, capitalizeWords } from "../../../../utils/utilidades";
import { fetchInfoUserAll, setUserAllData } from "../../../../store/slices/userAll";

const Applicant = ({ setShowModal, m, id_publicacion, func }) => {
  
  const [showDetailsUser, setshowDetailsUser] = useState([]);
  const [showDetailsUserTags, setshowDetailsUserTags] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [modalCompartir, setModalCompartir] = useState(false);

  const userData = useSelector(store => store.userAll);

  function showDetailsProfile(id){
    setshowModal(true)
    const searchObject= userData.datos.find((user) => user.id==id);
    const skillsMap = searchObject.habilidad.map(tag => ({ id: v4(), title: tag}));
    const interesesMap = searchObject.intereses.map(tag => ({ id: v4(), title: tag}));
    const lenguajesMap = searchObject.lenguajes.map(tag => ({ id: v4(), title: tag}));
    const tagsObject = {
      skills: skillsMap,
      interests:interesesMap,
      languages:lenguajesMap
    }
   
    
    setshowDetailsUser(searchObject)
    setshowDetailsUserTags(tagsObject)
  }
  const [modal, setModal] = useState(false);

  async function showAlert(id,id_publicacion){
    const dataUser = {
      id: id,
      id_publicacion: id_publicacion
    };
   const userSelected = await fetchToken({
      endpoint: "Publicacion/AddSeleccion",
      method: "POST",
      body: dataUser,
      alt: { error: true }
    });
    if(userSelected.FlgOk === 1){
      func();
      setModal(false)
    }
    
  }
  const compartirPost = async (idPostCompartir,correo) =>{
    const dataUser = {
      id: idPostCompartir,
      correo : correo
    };
   const userSelected = await fetchToken({
      endpoint: "Publicacion/AddCompartir",
      method: "POST",
      body: dataUser,
      alt: { error: true }
    });
    if(userSelected.FlgOk === 1){
      func();
      setModalCompartir(false)
    }
  }
  const [compartido, setCompartido] = useState([])
  const FetchData = async () => {
    const dataUsers = await fetchToken({ endpoint: `Publicacion/GetCompartido?fk_persona=${m.fk_persona}&fk_publicacion=${id_publicacion}` });
    setCompartido(dataUsers)
  }
  useEffect(() => {
    FetchData()
   
  }, [])
  return (
    <>
    {m.postulo != undefined && m.average >=50 ? (  
    <RowWrapper $padding="11px 0">
      <ItemBox $width="40%">
        <Icon
          back={m.background}
          size="32px"
          img={`${PATH}perfil/profile${m.img}.svg`}
        />
        <Title mb="0" size="14px">
          {capitalizeWords(m.nombreCargo)}
        </Title>
      </ItemBox>
      <ItemBox icon grow="1">
        <ItemBox jc="space-evenly" $width="30%">
          <Star fill={m.fav ? colors.mainBlue : colors.graySkeleton} title="Colaborador Favorito" />
          <CheckPostulo title="Colaborador postuló a este cargo"
            fill={m.postulo ? colors.mainBlue : colors.graySkeleton}
          />
        </ItemBox>
        <Hv />
        <ItemBox jc="space-around" wrap="wrap" gap="0" $width="30%">
          <img src={PATH + "img/Info.svg"} alt="info" onClick={()=> showDetailsProfile(m.fk_persona)}/>
          <Score back={m.average}>
            <p>{m.average >= 100 ? 100 : m.average }%</p>
          </Score>
          <Hv />

        </ItemBox>
        <ItemBox
        p="0 8px"
        >

        {!m.postulo ?(compartido != null && compartido.fk_persona === m.fk_persona ? (<img src={PATH + "img/shareok.svg"} alt="like" />) : (<img src={PATH + "img/icono-share.svg"} alt="like"  onClick={()=> setModalCompartir(true)} />)):(<img src={PATH + "img/sharedisable.svg"} alt="like" />)}
        </ItemBox>
        {
          m.postulo ? 
          <ItemBox
          
          onClick={() => setModal(m.seleccionado ? false : true)}
          jc="center"
          grow="1"
        >
        {m.seleccionado ?
          <img src={PATH + "img/check-ok.svg"} alt="like" /> : 
          <img src={PATH + "img/Add.svg"} alt="like" />
        }
        </ItemBox> :
        <ItemBox
         
          jc="center"
          grow="1"
        >
        <img src={PATH + "img/add-disable.svg"} alt="like" />
        </ItemBox>
        }
 
      </ItemBox>
    </RowWrapper>) : ""
    }
    {showModal && (
        <ContainerModal>
          <ModalWrapper>
            <BtnClose onClick={() => setshowModal(false)}>
              <img src={PATH + "img/Cerrar-azul.svg"} alt="Close" />
            </BtnClose>
            <Section>
              <div>
                <Icon img={PATH + "perfil/profile2.svg"} size="80px" />
              </div>
              <div>
                <Title mb="0" size="18px">
                   {capitalizeWords(showDetailsUser.nombreCargo)}
                </Title>
                <SubTitle lh="20px" $margin="0" size="14px">
                  Gerencia actual: <span>{capitalizeWords(showDetailsUser.nombreVpGerencia)}</span>
                </SubTitle>
                <SubTitle lh="20px" $margin="0" size="14px">
                  Estamento: <span>{showDetailsUser.estamento}</span>
                </SubTitle>
                <SubTitle lh="20px" $margin="0" size="14px">
                  Nivel de cargo: <span>{showDetailsUser.nivelCargo}</span>
                </SubTitle>
                <SubTitle lh="20px" $margin="0" size="14px">
                  Tiempo en el cargo: <span>{ añosCargo(showDetailsUser.fechaInicioCargo)} {añosCargo(showDetailsUser.fechaInicioCargo) <= 1 ? 'año' : 'años'}</span>
                </SubTitle>
              </div>
            </Section>
            <Hr $margin="24px" />
            <CapSkills itemsData={showDetailsUserTags} />
            <Hr $margin="24px" />
            <div>
              <Title mb="0" size="16px">
                Descripcion personal:
              </Title>
              <SubTitle lh="24px" $margin="0" size="16px">
                {showDetailsUser.descripcion}
              </SubTitle>
            </div>
            <Hr $margin="24px" />
          </ModalWrapper>
        </ContainerModal>
      )}
      <Alert
      id={m.fk_persona}
      estado={modal}
      shoModal={setModal}
      >
        <img src={PATH + "img/icono-info.svg"} alt="info"/>
        <h3>Confirmación</h3>
        <p>¿Estás seguro de que deseas
         <span>agregar a este usuario a la etapa de selección?</span>
        </p>
        <div>
        <ContainerButton>
        <BtnCancelar onClick={()=> setModal(false)}>Cancelar</BtnCancelar>
        <BtnBlue onClick={() => showAlert(m.fk_persona, id_publicacion)}>Agregar</BtnBlue>
        </ContainerButton>

        </div>
    </Alert>
    <Alert
      estado={modalCompartir}
      >
        <img src={PATH + "img/icono-info.svg"} alt="info"/>
        <h3>Confirmación</h3>
        <p>¿Estás seguro de que deseas <strong>compartir la publicación
          </strong>
        </p>
        <div>
        <ContainerButton>
        <BtnCancelar onClick={()=> setModalCompartir(false)}>Cancelar</BtnCancelar>
        <BtnBlue onClick={() => compartirPost(id_publicacion, m.email)}>Aceptar</BtnBlue>
        </ContainerButton>

        </div>
    </Alert>
    </>

  );
};

const PostItems = ({ setShowModal, idPost }) => {
  const {token} = useSelector(store => store.usuario);

  const dispatch = useDispatch();
  const FetchData = async () => {
    const dataUsers = await fetchToken({ endpoint: "Persona/GetAll" });
    const dataSkills = await fetchToken({ endpoint: "Habilidad/GetAllTags" });
    const dataInteres = await fetchToken({ endpoint: "Interes/GetAllTags" });
    const dataLenguaje = await fetchToken({ endpoint: "Lenguaje/GetAllTags" });
    const skills = dataSkills;
    const interes = dataInteres;
    const lenguaje = dataLenguaje;


    let newData = dataUsers.map(item =>
      Object.assign({}, item, { habilidad: [], intereses: [], lenguajes: [] })
    );

    skills.forEach(obj => {
      newData.forEach(array1Obj => {
        if (obj.id === array1Obj.id) {
          array1Obj.habilidad.push(obj.nombre);
        }
      });
    });

    interes.forEach(obj => {
      newData.forEach(array1Obj => {
        if (obj.id === array1Obj.id) {
          array1Obj.intereses.push(obj.nombre);
        }
      });
    });
    lenguaje.forEach(obj => {
      newData.forEach(array1Obj => {
        if (obj.id === array1Obj.id) {
          array1Obj.lenguajes.push(obj.nombre);
        }
      });
    });
    dispatch(setUserAllData(newData));
  };

  const { listadoHabilidades, listadoLenguajes, listadoIntereses } =
    useSelector(
      store =>
        store.posts.totalPosts.filter(
          post => post.data.id === Number(idPost)
        )[0]
    );

  const [loading, setLoading] = useState(false);

  const [matches, setMatches] = useState([]);
  const [escuchaEvento, setEscuchaEvento] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const results = await fetchToken({
        endpoint: `Publicacion/GetTokenMatchTags?id=${idPost}`,
        alt: { dataSkills: [], dataLenguajes: [] },token:token
      });
      const calc = calculator({
        listadoLenguajes,
        listadoHabilidades,
        listadoIntereses,
        results
      });

      setMatches(calc);
      setLoading(false);
    })();
  }, [escuchaEvento]);

  useEffect(() => {
    FetchData();
  }, []);

  return (
    <>
    <div>
      <OffSet />
      <ContainerHalfCustom
        $max={matches.length === 0}
        section_scroll
        header_height="78"
      >
        <HeaderSection>
          <Title size="16px">Postulantes:</Title>
          <RowWrapper>
            <ItemBox p="0 0 0 50px" $width="40%">
              <Title size="14px">Cargo</Title>
            </ItemBox>
            <ItemBox jc="flex-start" $width="40%">
              <Title size="14px">Informacion</Title>
            </ItemBox>
            <ItemBox jc="flex-end" $width="20%">
              <Title size="14px">Me interesa</Title>
            </ItemBox>
          </RowWrapper>
        </HeaderSection>
        <section>
          {loading ? (
            <>
              <SkeletonContainer>
                <SkeletonLoader />
              </SkeletonContainer>
              <SkeletonContainer>
                <SkeletonLoader />
              </SkeletonContainer>
              <SkeletonContainer>
                <SkeletonLoader />
              </SkeletonContainer>
              <SkeletonContainer>
                <SkeletonLoader />
              </SkeletonContainer>
            </>
          ) : matches.length > 0 ? (
            matches.map(m => ( 
              <Applicant key={m.fk_persona} m={m} id_publicacion={idPost} func={e => {setEscuchaEvento(true)}} />
            ))
          ) : (
            <Title align="center" size="24px" bold="400">
              No hay resultados
            </Title>
          )}
        </section>
      </ContainerHalfCustom>
    </div>
    </>
  );
};
export default PostItems;
