import { AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PATH } from "../../../../assets/variables";
import { fetchInfoUser, updateInfoUser } from "../../../../store/slices/user";
import { clearUserMatch } from "../../../../store/slices/userAll";
import { ContainerModal, Hr, SubTitle, Title } from "../../../../Styled";
import { removeParentesis } from "../../../../utils/utilidades";
import { Icon } from "../../Common/IconProfile/Styled";
import { Loader } from "../../Common/LoaderModal/LoaderModal";
import { ContainerImportante, SuggestButton } from "../Suggest/Styled";
import UserImgPicker from "./ImgUserPicker/UserImgPicker";
import InputWithSearch from "./InputWithSearch/InputWithSearch";
import {
  AlertaContainer,
  BtnSave,
  ContainerBtnSave,
  ContainerImgProfile,
  FormUserContainer,
  ImgEdit,
  Section
} from "./Styled";
import Suggest from "../Suggest/Suggest";
import { FilesContainerNew, InputFile, UploadInput, UploadLabelNew } from "../../OfferForm/Form/Files/Styled";
import { addCV } from "../../../../store/slices/posts";

const FormUser = () => {
  const alertaRef = useRef();
  const [formModified, setFormModified] = useState(false);
  const [visibleAlerta, setVisibleAlerta] = useState(false);
  const [imgUser, setImgUser] = useState({ background: "#fff", avatar: 1 });
  const [showModal, setShowModal] = useState(false);
  const [characters, setCharacters] = useState("");
  const [load, setLoad] = useState(false);
  const [fileCV, setFileCV] = useState(null);

  const [dataSets, setDataSets] = useState({
    skills: [],
    languages: [],
    interests: []
  });

  const dispatch = useDispatch();

  const userData = useSelector(store => store.user);
  const token = useSelector(store => store.usuario.token);

  const handleFileChange = (event) => {
    
    const newFiles = [];
    const selectedFiles = Array.from(event.target.files);
    newFiles.push(...selectedFiles);
    setFileCV(newFiles);
    console.log(fileCV)

  };


  const handleInputs = e => {
    if (e.target.value === " ") {
      return;
    }

    if (e.target.name === "description") {
      e.target.value.replace("  ", " ").length <= 200 &&
        setCharacters(e.target.value.replace("  ", " "));
      setFormModified(true);
    }
  };

  const handleModal = e => {
    if (e.target.dataset.modal) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    setDataSets({
      skills: userData.skills,
      languages: userData.languages,
      interests: userData.interests
    });
    setImgUser({
      background: userData.data?.background || "#fff",
      avatar: userData.data?.img || 1
    });
    setCharacters(userData.data?.descripcion || "");
  }, [userData]);

  const sendInfo = e => {
    // if (!formModified) {
    //   return;
    // }

    if(fileCV){
      console.log(fileCV)
      const cvData = {
        nombreArchivo: fileCV[0].name,
        fk_persona: userData.data.idColab,
      }
      dispatch(addCV(cvData,fileCV));

    }

    setLoad(true);
    const objData = {
      img: imgUser.avatar,
      background: imgUser.background,
      descripcion: characters,
      habilidades: dataSets.skills.map(s => ({
        fk_habilidad: s.id,
        nivel: s.level
      })),
      intereses: dataSets.interests.map(i => ({
        fk_interes: i.id
      })),
      lenguajes: dataSets.languages.map(l => ({
        fk_lenguaje: l.id,
        nivel: l.level
      }))
    };

    dispatch(updateInfoUser(objData, userData, () => alertaExito(), token));
    dispatch(clearUserMatch([]))
    setFormModified(false);

  };
  function alertaExito(){
    setLoad(false)
    setVisibleAlerta(true)
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
    setTimeout(() => {
      setVisibleAlerta(false)
    }, 10000);
  }
  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ContainerModal
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            data-modal="true"
            onClick={handleModal}
          >
            <UserImgPicker
              setFormModified={setFormModified}
              backgroundPrev={imgUser?.background}
              img={imgUser.avatar}
              setImgUser={setImgUser}
              setShowModal={setShowModal}
            />
          </ContainerModal>
        )}
        {load && <Loader text="Guardando informacion" />}
      </AnimatePresence>
      <div>
        {visibleAlerta && (
        <AlertaContainer  ref={alertaRef}>
          <img src={PATH + "img/check-ok.svg"} alt="like" />
        Perfil actualizado exitosamente
        </AlertaContainer>
        )}
        <FormUserContainer>
        <Section flex gap="24px">
          <ContainerImgProfile>
            <Icon
              img={`${PATH}perfil/profile${imgUser.avatar}.svg`}
              size="90px"
              back={imgUser?.background}
            />
            <ImgEdit
              onClick={() => setShowModal(true)}
              src={PATH + "img/Editar.svg"}
            />
          </ContainerImgProfile>
          <div>
            <Title mb="0" size="18px">
              {userData.data?.nombreCargo}
            </Title>
            <SubTitle lh="20px" $margin="0" size="14px">
              Gerencia actual:{" "}
              <span>
                { removeParentesis(userData.data?.nombreVpGerencia || "Sin informacion")}
              </span>
            </SubTitle>
            <SubTitle lh="20px" $margin="0" size="14px">
              Estamento:{" "}
              <span>{removeParentesis(userData.data?.estamento || "Sin informacion")}</span>
            </SubTitle>
            <SubTitle lh="20px" $margin="0" size="14px">
              Nivel de cargo:{" "}
              <span>{userData.data?.nivelCargo || "Sin informacion"}</span>
            </SubTitle>
            <SubTitle lh="20px" $margin="0" size="14px">
              Evaluación desempeño:{" "}
              <span>{userData.data?.desempCriterio || "Sin informacion"}</span>
            </SubTitle>
          </div>
        </Section>
        <Hr $margin="24px 0" />
        <Section>
          <Title size="16px">Descripcion Personal</Title>
          <textarea
            onChange={handleInputs}
            name="description"
            placeholder="Escribe una breve descripción profesional y personal, tienes hasta 200 caracteres. Recuerda no poner tu nombre completo ni apodos que te puedan identificar."
            value={characters}
          />
          <Section flex jc="flex-end">
            <div>{characters.length}/200</div>
          </Section>
        </Section>
        <Hr $margin="24px 0" />
        <section>
        <label htmlFor="">
        <Title color="blue" size="18px" bold="500">
        Carga tu CV Aquí:
          </Title>
        <FilesContainerNew>
        <InputFile>
        <h5>{fileCV ? fileCV[0].name : userData.data.urlCV}</h5>
        <UploadInput
              id="upload2"
              type="file"
              onChange={handleFileChange}
            />
        <UploadLabelNew htmlFor="upload2">{userData.data.urlCV != null ? "Actualizar" : "Adjuntar"}</UploadLabelNew>
        </InputFile>
      </FilesContainerNew>

        </label>
        </section>
        <ContainerImportante>
        <h4>
            <img src={PATH + "img/icono-importante.svg"}  />
            Importante:
        </h4>
        <p>
        Si estas buscando sugerir alguna habilidad, interés o idioma, puedes enviarnos tu sugerencia haciendo click en el botón<strong>"Sugerir".</strong>
        </p>
          <Suggest></Suggest>
        </ContainerImportante>
        <InputWithSearch
          name="skills"
          data={dataSets.skills}
          setDataSets={setDataSets}
          title="habilidades"
          setFormModified={setFormModified}
        />
        <Hr $margin="24px 0" />
        <InputWithSearch
          name="interests"
          data={dataSets.interests}
          setDataSets={setDataSets}
          title="intereses"
          setFormModified={setFormModified}
        />
        <Hr $margin="24px 0" />
        <InputWithSearch
          name="languages"
          data={dataSets.languages}
          setDataSets={setDataSets}
          title="idiomas"
          setFormModified={setFormModified}
        />
        <ContainerBtnSave>
          <BtnSave onClick={sendInfo} modified={formModified}>
            Guardar cambios <img src={PATH + "img/ArrowRight.svg"} alt="save" />
          </BtnSave>
        </ContainerBtnSave>

        </FormUserContainer>
      </div>
    </>
  );
};

export default FormUser;
