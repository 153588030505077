import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PATH } from "../../../assets/variables";
import { addAttachment, addAttachmentBlob, fetchToken } from "../../../customHooks/hooks";
import { addPost, fetchGetPosts, updPost } from "../../../store/slices/posts";
import { setInputFile2 } from "../../../store/slices/userAll";
import { Hr, Title } from "../../../Styled";
import { Loader } from "../../Common/LoaderModal/LoaderModal";
import InputWithSearch from "../../UserRegister/FormUser/InputWithSearch/InputWithSearch";
import { BtnSave } from "../../UserRegister/FormUser/Styled";
import { OffSet } from "../../VacanciesDetails/Style";
import { ContainerHalfCustom } from "../Styled";
import Files from "./Files/Files";
import FilesTwo from "./Files/FilesTwo";
import InputFields from "./InputFields/InputFields";
import { ContainerImg } from "./Styled";
import TextAreas from "./TextAreas/TextAreas";
import FilesStory from "./Files/FilesStory";

const Form = ({ newCapData, setNewCapData, upd }) => {
  const lideres = useSelector(store => store.posts.selectors.lideres);
  const token = useSelector(store => store.user.token);
  const [archivos, setArchivos] = useState([])
  const [archivos2, setArchivos2] = useState([])

  const [archivosfinal, setArchivosFinal] = useState(['',''])


  const inputFile = useSelector(store => store.userAll.inputFile);
  const inputFile2 = useSelector(store => store.userAll.inputFile2);
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);

  const handleChange = e => {
    if (e.target.value === " ") {
      return;
    }
    setNewCapData({
      ...newCapData,
      [e.target.name]: e.target.value.replace("  ", " ")
    });
  };


  const sendInfo = async e => {
    // if (!validator()) {
    //   return;
    // }
    if (!validator()) {
      alert('Hemos detectado algunos datos sin completar. Para crear la publicacion se deben ingresar los campos.')
      return;
    }



    setLoad(true);

    const type = newCapData.file.type;
    const type3 = newCapData.file3.type;

    const objServer = {
      cargo: newCapData.cargo,
      gerencia: newCapData.gerencia,
      estamento: newCapData.estamento,
      ubicacion: newCapData.ubicacion,
      jornada: newCapData.jornada,
      mision: newCapData.mision,
      funciones: newCapData.funciones,
      descripcion: newCapData.descripcion,
      fecha_vencimiento: newCapData.fechaVencimiento,
      fk_lider: newCapData.lider,
      habilidades: newCapData.skills.map(s => ({
        fk_habilidad: s.id,
        nivel: s.level
      })),
      intereses: newCapData.interests.map(i => ({
        fk_interes: i.id
      })),
      lenguajes: newCapData.languages.map(l => ({
        fk_lenguaje: l.id,
        nivel: l.level
      })),
      archivo: `${newCapData.file.name};#${type}`,
      archivo2: `${newCapData.file2.name};#${type}`,
      archivo3: `${newCapData.file3 ? newCapData.file3.name : newCapData.file.name};#${type3}`,
      pais: 'Chile'

    };
    setNewCapData({
      cargo: "",
      gerencia: "",
      estamento: "",
      jornada: "",
      ubicacion: "",
      fechaVencimiento: "",
      lider: 0,
      file: "",
      file2: "",
      file3:"",
      mision: "",
      descripcion: "",
      funciones: "",
      skills: [],
      languages: [],
      interests: []
    });

    // const url = await addAttachment(newCapData.file);
    // objServer.archivo = `${url};#${type}`;
    // const url2 = await addAttachment(newCapData.file2);
    // objServer.archivo2 = `${url2};#${type}`;



    dispatch(addPost(objServer, () => setLoad(false),token, archivos));
  };
  function enviarInfo(){
    // addAttachmentBlob(archivos)
  }
  const updInfo = async e => {
    // if (!validator()) {
    //   return;
    // }

    setLoad(true);


    const objServer = {
      idPost: newCapData.idPost,
      cargo: newCapData.cargo,
      gerencia: newCapData.gerencia,
      estamento: newCapData.estamento,
      ubicacion: newCapData.ubicacion,
      jornada: newCapData.jornada,
      mision: newCapData.mision,
      funciones: newCapData.funciones,
      descripcion: newCapData.descripcion,
      fecha_vencimiento: newCapData.fechaVencimiento,
      fk_lider: newCapData.lider,
      habilidades: newCapData.skills.map(s => ({
        fk_habilidad: s.id,
        nivel: s.level
      })),
      intereses: newCapData.interests.map(i => ({
        fk_interes: i.id
      })),
      lenguajes: newCapData.languages.map(l => ({
        fk_lenguaje: l.id,
        nivel: l.level
      }))
    };
    // setNewCapData({
    //   idPost: "",
    //   cargo: "",
    //   gerencia: "",
    //   estamento: "",
    //   jornada: "",
    //   ubicacion: "",
    //   fechaVencimiento: "",
    //   lider: 0,
    //   file: "",
    //   file2: "",
    //   mision: "",
    //   descripcion: "",
    //   funciones: "",
    //   skills: [],
    //   languages: [],
    //   interests: []
    // });

    if(inputFile && inputFile2 === false){
      const type = newCapData.file.type;
      const url = await newCapData.file.name;
      objServer.archivo = `${url};#${type}`;
      objServer.archivo2 = newCapData.filePrev2;
      objServer.archivo2 = objServer.archivo2.split(objServer.idPost+'-')[1]
    }
    if(inputFile === false && inputFile2){
      objServer.archivo = newCapData.filePrev;
      objServer.archivo = objServer.archivo.split(objServer.idPost+'-')[1]
      const type = newCapData.file2.type;
      const url2 = newCapData.file2.name;
      objServer.archivo2 = `${url2};#${type}`;
    }
    if(inputFile && inputFile2){
      const type = newCapData.file.type;
      const url = newCapData.file.name;
      objServer.archivo = `${url};#${type}`;
      const type2 = newCapData.file2.type; 
      const url2 = newCapData.file2.name;
      objServer.archivo2 = `${url2};#${type2}`;
    }
    if(inputFile === false && inputFile2 === false){
      objServer.archivo = newCapData.filePrev;
      objServer.archivo2 = newCapData.filePrev2;
      objServer.archivo = objServer.archivo.split(objServer.idPost+'-')[1]
      objServer.archivo2 = objServer.archivo2.split(objServer.idPost+'-')[1]
    }

    
    lideres.map(x => {
      if(x.id == newCapData.lider){
        objServer.fk_lider =x.fk_colaborador
      }
    })
    // objServer.archivo = objServer.archivo.split(objServer.idPost+'-')[1]
    // objServer.archivo2 = objServer.archivo2.split(objServer.idPost+'-')[1]
    if(newCapData.file3 != undefined){
     objServer.archivo3 = newCapData.file3.name
    }
    else{
      objServer.archivo3 = newCapData.filePrev3
      objServer.archivo3= objServer.archivo3.split(objServer.idPost+'-')[1]

    }
    dispatch(updPost(objServer, () => setLoad(false),token, archivos));
    dispatch(setInputFile2(false))
    dispatch(setInputFile2(false))

    
  };


  const validator = () => {
    return (
      newCapData.cargo !== "" &&
      newCapData.gerencia &&
      newCapData.estamento !== "" &&
      newCapData.jornada !== "" &&
      newCapData.ubicacion !== "" &&
      newCapData.fechaVencimiento !== "" &&
      newCapData.lider > 0 &&
      newCapData.file &&
      newCapData.mision !== "" &&
      newCapData.descripcion !== "" &&
      newCapData.funciones !== "" &&
      newCapData.skills.length > 0 &&
      newCapData.languages.length > 0 &&
      newCapData.interests.length > 0
    );
  };

  return (
    <div>
      <AnimatePresence>
        {load && <Loader text="Creando nueva publicacion..." />}
      </AnimatePresence>
      <OffSet />
      <ContainerHalfCustom scroll>
        <ContainerImg />
        <Title size="22px" bold="500" lh="26px" mb="16px">
          Formulario de nueva oferta
        </Title>

        <Title color="blue" size="18px" mb="40px" lh="22px" bold="500">
          Rellena los datos de la solicitud:
        </Title>

        <form>
          <InputFields
            newCapData={newCapData}
            setNewCapData={setNewCapData}
            handleChange={handleChange}
            upd={upd}
          />
          <Hr $margin="32px 0" />
          <Title color="blue" size="18px" bold="500">
          Adjuntar una imagen para el icono:<br></br>
          <small>Tamaño recomendado 80px ancho y 80px alto</small>
          </Title>
          <FilesTwo newCapData={newCapData} setNewCapData={setNewCapData} setArchivos={setArchivos} archivos={archivos}/>
          <Hr $margin="24px 0" />
          <Title color="blue" size="18px" bold="500">
          Adjuntar imagen o video historia destacada<br></br>
          <small>Tamaño recomendado 350px ancho y 1000px alto</small>
          </Title>
          <FilesStory newCapData={newCapData} setNewCapData={setNewCapData} setArchivos={setArchivos} archivos={archivos}/>
          <Title color="blue" size="18px" bold="500">
          Adjuntar una imagen o video para la publicación:<br></br>
            <small>Tamaño recomendado 600px ancho y 240px alto</small>
          </Title>
          <Files newCapData={newCapData} setNewCapData={setNewCapData} setArchivos={setArchivos} archivos={archivos}/>
          <TextAreas newCapData={newCapData} setNewCapData={setNewCapData} />
          <InputWithSearch
            name="skills"
            data={newCapData.skills}
            setDataSets={setNewCapData}
            title="habilidades"
            optionsOffer={true}
          />
          <Hr $margin="24px 0" />
          <InputWithSearch
            name="interests"
            data={newCapData.interests}
            setDataSets={setNewCapData}
            title="intereses"
            optionsOffer={true}
          />
          <Hr $margin="24px 0" />
          <InputWithSearch
            name="languages"
            data={newCapData.languages}
            setDataSets={setNewCapData}
            title="idiomas"
            optionsOffer={true}
          />
          <Hr $margin="24px 0" />
          {upd ? (
              <BtnSave onClick={updInfo} modified={true}>
              Actualizar <img src={PATH + "img/IconSend.svg"} alt="save" />
            </BtnSave>

          ) :(
            <BtnSave onClick={sendInfo} modified={validator()}>
              Guardar cambios <img src={PATH + "img/IconSend.svg"} alt="save" />
            </BtnSave>
          )}
        </form>
      </ContainerHalfCustom>
    </div>
  );
};

export default Form;
