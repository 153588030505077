import React, { useEffect } from "react";
import MasterPagePeru from "./peru/components/MasterPage/MasterPage";
import MasterPage from "./components/MasterPage/MasterPage";

import { useDispatch, useSelector } from "react-redux";
import { GlobalStyle } from "./Styled";
import { MemoryRouter } from "react-router-dom";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useState } from "react";
import { msalConfig } from "./auth/msalConfig";
import { InteractionStatus } from "@azure/msal-browser";
import { setToken, setUsuarioData } from "./store/slices/usuario";
import NavigationComponentsPeru from "../src/peru/components/NavigationComponents/NavigationComponents"
import NavigationComponents from "../src/components/NavigationComponents/NavigationComponents"
import { Loader } from "./components/Common/LoaderModal/LoaderModal";
import { fetchInfoUser } from "./store/slices/user";


///sites/SeleccionDeTalento/SiteAssets/build/
export const useGetToken = () => {
  // const token = authContext.getCachedToken(adalConfig.clientId);
  const userToken = useSelector(store => store.usuario.token);
  return userToken
};
function App() {

  // useEffect(() => {
  //   dispatch(fetchGeneralData());
  // }, []);


  const [pais, setPais] = useState("")
  const [load, setLoad] = useState(true)

  const dispatch = useDispatch();
  const isAutenticated = useIsAuthenticated();
  const { instance, accounts, inProgress  } = useMsal();
  const account = useAccount(accounts[0] || {});

  // useEffect(() => {
  //   if (inProgress === InteractionStatus.None && !isAutenticated) {
  //     instance.loginRedirect(msalConfig);
  //   }
  // }, [inProgress, isAutenticated, instance]);

  // // Obtenemos el token de Office y lo enviamos a la API
  // useEffect(() => {
  //   const getTokenAndFetchData = async () => {
  //     if (account) {
  //       try {
  //         const response = await instance.acquireTokenSilent({
  //           account: account,
  //         });

  //         const token = response.idToken;
  //         console.log(token)
  //         fetch(`https://api-selecciontalento.azurewebsites.net/api/Colaborador/Login?token=${token}`, {
  //           method: 'GET',
  //           mode: 'cors',
  //           headers: {
  //             'Content-Type': 'application/json',
  //             // token: token,
  //           },
  //         })
  //           .then((res) => res.json())
  //           .then((data) => {
  //             // Si el usuario no existe en colaboradores, enviamos a la pantalla de inicio de sesión de Office
  //             if (data.FlgOK === -100) {
  //               instance.logoutRedirect(msalConfig);
  //             } else {
  //               dispatch(setUsuarioData(data.colab));
  //               dispatch(setToken(data.token));
  //               setPais(data.token);
  //             }
  //           });
  //       } catch (error) {
  //         if (error.name === 'InteractionRequiredAuthError') {
  //           try {
  //             await instance.logoutRedirect(msalConfig);
  //             await getTokenAndFetchData();
  //           } catch (error) {
  //             // Manejar el error de inicio de sesión
  //             console.log('Error al iniciar sesión:', error);
  //           }
  //         } else {
  //           // Manejar otros errores
  //           console.log('Error al obtener el token de acceso:', error);
  //         }
  //       }
  //     }
  //   };

  //   getTokenAndFetchData();
  // }, [account, instance]);  

  if (inProgress === InteractionStatus.None && !isAutenticated) {
    instance.loginRedirect(msalConfig);
  }
  //Obtenemos token office y lo enviamos a la API.
  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          account: account,
        })
        .then((response) => {
          return response.idToken
        })
        .then((token) => {
          fetch(`https://api-selecciontalento.azurewebsites.net/api/Colaborador/Login?token=${token}`, {
            method: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json"
              // token: token,
            },
          }).then((res) => {
            return res.json()
          }).then((data)=> {
            //Si el usuario no existe en colaboradores enviamos a pantalla de login office, sino guardamos data de colab, el pais y su token.
            if(data.FlgOK != -100){
              setLoad(false)
              dispatch(setUsuarioData(data.colab))
              dispatch(setToken(data.token))
              setPais(data.colab.Pais)
              // dispatch(fetchInfoUser(data.token));
            }
            else{
              instance.logoutRedirect(msalConfig)
            }
          })
        });
    }
  }, []);
  
  return (
    // <MasterPage>
    //   <NavigationComponents />
    // </MasterPage>
    <>
     {load && <Loader text="Cargando..." />}
      {
        pais === 'Chile' ? (
<>
<MemoryRouter initialEntries={["/"]} initialIndex={0}>
<GlobalStyle />
<MasterPage>
<NavigationComponents />
</MasterPage>
</MemoryRouter>
    </>
        ) : pais === 'Peru' ? (
          <>
          <MemoryRouter initialEntries={["/"]} initialIndex={0}>
        <GlobalStyle />
        <MasterPagePeru>
         <NavigationComponentsPeru />
        </MasterPagePeru>
       </MemoryRouter>
              </>
        ) : ""

    
      }
    </>

  );
}


//"homepage": "/sites/SeleccionDeTalento/SiteAssets/build",
//"homepage": "/sites/SeleccionDeTalento/SiteAssets/Test.aspx",

export default App;
